// pages/Articles/Flow.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Resources from './index'; 
import Recipe from './Recipe'; 
import Article from './Article'; 
import Newsletter from './Newsletter'; 
import Resource from './../Employer/Resource'; 
import ResourceList from './ResourceList'; 

const ArticlesFlow = () => {
    return (
        <Routes>
            <Route 
                path="/" 
                element={<Resources />} 
            />
            <Route 
                path="/recipe/:id" 
                element={<Recipe />} 
            />
            <Route path="newsletter/:id" element={<Newsletter />} />
            <Route path="employer/:id" element={<Resource />} />
            <Route path=":type/:id" element={<Article />} />
            <Route path=":type" element={<ResourceList />} />
        </Routes>
    );
};

export default ArticlesFlow;
