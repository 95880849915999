import React, { useEffect } from 'react';

import WordCloud from 'wordcloud'

const WordcloudChart = ({ data }) => {

    const setup = () => {
        let list = [];

        data.forEach((word) => {
            var wordObject = [word.word, word.usage, word.colour];
            list.push(wordObject);
        })

        let listColorCounter = 0;
        WordCloud.minFontSize = "15px"
        WordCloud(document.getElementById('cloud'), {
            list: list,
            color: function () {
                return list[listColorCounter++][2];
            },
            rotateRatio: 0,
            backgroundColor: "transparent"
        });
    }

    useEffect(() => {
        setup()
    });

    return (
        <canvas id="cloud"></canvas>
    );
};

export default WordcloudChart;
