import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../../../components/Elements/Image';

const ArticleCardSentiment = ({ item }) => {
    return (
        <div>
            <Image
                src={item.pdf_thumbnail} alt={item.pdf_name} className="w-full rounded-xl lg:rounded-2xl aspect-video object-cover"
            />
            <div className='mt-4'>
                <h3 className="h4 mb-2">{item.pdf_name}</h3>
                <Link
                    to={item.pdf_document}
                    className="btn btn-invert block lg:inline-block">
                    Download
                </Link>
            </div>
        </div>
    );
};

export default ArticleCardSentiment;